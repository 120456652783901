import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContactApi } from 'shared/sdk';
import { filter, distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-jobsite-key-contact-lookup',
  templateUrl: './jobsite-key-contact-lookup.component.html',
  styleUrls: ['./jobsite-key-contact-lookup.component.css']
})
export class JobsiteKeyContactLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this._jobsiteKeyContactSelected = [];
  }
  @Input()
  set isMultipleSelected(e) {
    this._isMultipleSelected = e;
    this.maxSelectedItems = e ? 20 : 1;
  }
  @Input() isdisabled = false;
  @Input() hideSelected = false;
  @Output() getJobsiteKeyContactObj: EventEmitter<any> = new EventEmitter;
  noJobsiteKeyContactFound = 'No jobsite key contact found.';
  jobsiteTypeahead = new EventEmitter<string>();
  jobsiteKeyContactList = [];
  @Input()
  set jobsiteSelected(e) {
    this._jobsiteKeyContactSelected = e;
    this.getSelectedJobsiteData();
  }
  @Input() isGlobalFilter = true;

  maxSelectedItems = 20;
  _isMultipleSelected = true;
  _jobsiteKeyContactSelected = [];
  _jobsiteSelectedObject = {};
  constructor(private _contactApi: ContactApi,
  ) {

  }

  ngOnInit() {
    this.getJobsiteContact();
    // to show selected user
    if (this.isGlobalFilter) {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this._jobsiteKeyContactSelected = (preselected && preselected['users']) ? preselected['users'] : '';
    }
    this.getSelectedJobsiteData();
  }

  getSelectedJobsiteData() {
    if (this._jobsiteKeyContactSelected && this._jobsiteKeyContactSelected.length) {
      let whereObj = {};
      if (Array.isArray(this._jobsiteKeyContactSelected)) {
        whereObj['sfdcId'] = { inq: this._jobsiteKeyContactSelected };
      } else {
        whereObj['sfdcId'] = this._jobsiteKeyContactSelected;
      }
      this.getJobsiteKeyList(whereObj).subscribe(
        (x: any) => {
          this.jobsiteKeyContactList = x;
          let data = [];
          data = this.jobsiteKeyContactList.filter(item => {
            return item.sfdcId === this._jobsiteKeyContactSelected;
          });
          this._jobsiteSelectedObject = data[0];
          this.getJobsiteKeyContactObj.emit(this._jobsiteSelectedObject);
          this.noJobsiteKeyContactFound = (this.jobsiteKeyContactList.length === 0) ? 'No jobsite key contact found.' : '';
        },
        err => {
          console.log(err);
          this.jobsiteKeyContactList = [];
          this.noJobsiteKeyContactFound = '';
        }
      );
    }

  }

  // get Jobsite Key Contact list by name
  getJobsiteContact() {
    this.jobsiteKeyContactList = [];
    this.jobsiteTypeahead
      .pipe(
        filter(term => term && term.trim().length > 2),
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(term => {
          const searchPattern = { like: '%' + term.trim() + '%', options: 'i' };
          const whereObj = {
            Name: searchPattern,
            sfdcId: { neq: null }
          };
          return this.getJobsiteKeyList(whereObj);
        })
      )
      .subscribe(
        (x: any) => {
          this.jobsiteKeyContactList = x;
          this.noJobsiteKeyContactFound = (this.jobsiteKeyContactList.length === 0) ? 'No User found.' : '';
        },
        err => {
          console.log(err);
          this.jobsiteKeyContactList = [];
          this.noJobsiteKeyContactFound = '';
        }
      );
  }

  onJobsiteChange(event) {
    this._jobsiteSelectedObject = event;
    this.getJobsiteKeyContactObj.emit(this._jobsiteSelectedObject);
  }

  clearUsers() {
    this.jobsiteKeyContactList = []; 
    this._jobsiteKeyContactSelected = [];
    if (this._isMultipleSelected) {
      this.getJobsiteKeyContactObj.emit([]);
    } else {
      this.getJobsiteKeyContactObj.emit(null);
    }
  }

  /**
   * 
   * @param whereObj 
   */

  getJobsiteKeyList(whereObj) {
    return this._contactApi.find({
      where: whereObj,
      fields: ['sfdcId', 'Name', 'Phone', 'Email']
    }).map(res => res);
  }
}
